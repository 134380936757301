<template>
  <div class="trips">
    <lenon-list-page
        title="Trips"
        :columns="columns"
        :rows="trips"
        :table-loading="tableLoading"
        :show-create="false"
        :show-refresh="false"
        :show-search="false"
        :show-details="true"
        search-placeholder="Search"
    >
      <template slot="table-header">
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="col-12"
        >
          <b-collapse
              id="trip"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-button
                    icon="SettingsIcon"
                    label="Trips"
                    tool-tip-text="Manage Trips(CRUD)"
                    class="mb-1"
                    @onClick="showTripsModal()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex align-self-center align-items-start"
        >
          <lenon-button
              icon="SettingsIcon"
              label="Trips"
              tool-tip-text="Manage Trips(CRUD)"
              class="mb-1"
              @onClick="showTripsModal()"
          />
        </div>
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex align-self-center align-items-center"
        >
          <lenon-button
              v-b-toggle.trip
              icon-only
              icon="MoreVerticalIcon"
              variant="flat-primary"
          />
        </div>
      </template>
      <template #driver_id="{row}">
        <b-badge variant="light-primary">
          {{ getUsersDetails([row.item.driver_id]) }}
        </b-badge>
      </template>
      <template #vehicle_id="{row}">
        <b-badge variant="light-secondary">
          {{ getVehicleDetails(row.item.vehicle_id) }}
        </b-badge>
      </template>
      <template #conductors="{row}">
        <b-badge variant="light-success">
          {{ getUsersDetails(row.item.conductors) }}
        </b-badge>
      </template>
      <template #actions="{ row }">
        <lenon-button
            icon-only
            variant="flat-primary"
            :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
            tool-tip-text="View pickup points"
            @onClick="row.toggleDetails"
        />
      </template>
      <template #row-details="{ row }">
        <b-card>
          <div class="row">
            <div class="col-12">
              <strong>Pickup Points</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-wrap">
                <b-badge variant="light-success" v-for="(item,i) in getLocations(row.item.pickup_points)" :key="i">
                  {{ item }}
                </b-badge>
              </div>
            </div>
          </div>
        </b-card>
      </template>
    </lenon-list-page>

    <trips-setup
        :modal-opened="tripsModalOpened"
        @modalClosed="closeModal('trips')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import TripsSetup from '@/views/transportation/trips/setup/TripSetup.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import { VBToggle, BCollapse, BBadge, BCard } from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'

export default {
  name: 'Trips',
  components: {
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    TripsSetup,
    LenonButton,
    BBadge,
    BCollapse,
    BCard
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      tripsModalOpened: false,
      tableLoading: false,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'driver_id',
          label: `Driver`,
        },
        {
          key: 'vehicle_id',
          label: `Vehicle`,
        },
        {
          key: 'conductors',
          label: 'Conductors',
        },
        {
          key: 'actions',
          label: 'Details',
        },
      ],
    }
  },
  computed: {
    trips() {
      return this.$store.getters['trip/trips']
    },
    vehicles() {
      return this.$store.getters['vehicle/vehicles']
    },
    pickupPoints() {
      return this.$store.getters['pickupPoint/pickupPoints']
    },
    users() {
      return this.$store.getters['accountUsers/users']
    },
  },
  watch: {},
  mounted() {
    this.fetchTrips()
  },
  methods: {
    getLocations(ids){
      return this.pickupPoints.filter((p)=>ids.includes(+p.id)).map((p)=>p.label)
    },
    getVehicleDetails(id){
      return this.vehicles.find((u) => +u.id===+id).label
    },
    getUsersDetails(ids) {
      return this.users.filter((u) => ids.includes(+u.id))
          .map((u) => u.full_name)
          .join(', ')
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'trips':
          this.tripsModalOpened = false
          break
      }
    },
    showTripsModal() {
      this.tripsModalOpened = true
    },

    fetchTrips() {
      this.tableLoading = true
      this.$http.get('transportation/trips')
          .then(res => {
            this.$store.commit('trip/setTrips', res.data)
          })
          .catch(err => {
            this.showError('Failed to load fee trips')
          })
          .finally(() => {
            this.tableLoading = false
          })
    },
  },
}
</script>
