var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trips"},[_c('lenon-list-page',{attrs:{"title":"Trips","columns":_vm.columns,"rows":_vm.trips,"table-loading":_vm.tableLoading,"show-create":false,"show-refresh":false,"show-search":false,"show-details":true,"search-placeholder":"Search"},scopedSlots:_vm._u([{key:"driver_id",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.getUsersDetails([row.item.driver_id]))+" ")])]}},{key:"vehicle_id",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{attrs:{"variant":"light-secondary"}},[_vm._v(" "+_vm._s(_vm.getVehicleDetails(row.item.vehicle_id))+" ")])]}},{key:"conductors",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.getUsersDetails(row.item.conductors))+" ")])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('lenon-button',{attrs:{"icon-only":"","variant":"flat-primary","icon":row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon',"tool-tip-text":"View pickup points"},on:{"onClick":row.toggleDetails}})]}},{key:"row-details",fn:function(ref){
var row = ref.row;
return [_c('b-card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('strong',[_vm._v("Pickup Points")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.getLocations(row.item.pickup_points)),function(item,i){return _c('b-badge',{key:i,attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(item)+" ")])}),1)])])])]}}])},[_c('template',{slot:"table-header"},[_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(_vm.$store.getters['app/smallScreen']),expression:"$store.getters['app/smallScreen']"}],staticClass:"col-12"},[_c('b-collapse',{attrs:{"id":"trip"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('lenon-button',{staticClass:"mb-1",attrs:{"icon":"SettingsIcon","label":"Trips","tool-tip-text":"Manage Trips(CRUD)"},on:{"onClick":function($event){return _vm.showTripsModal()}}})],1)])])],1)]),_c('template',{slot:"right-extra-header-actions"},[_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(_vm.$store.getters['app/largeScreen']),expression:"$store.getters['app/largeScreen']"}],staticClass:"d-flex align-self-center align-items-start"},[_c('lenon-button',{staticClass:"mb-1",attrs:{"icon":"SettingsIcon","label":"Trips","tool-tip-text":"Manage Trips(CRUD)"},on:{"onClick":function($event){return _vm.showTripsModal()}}})],1),_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(_vm.$store.getters['app/smallScreen']),expression:"$store.getters['app/smallScreen']"}],staticClass:"d-flex align-self-center align-items-center"},[_c('lenon-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.trip",modifiers:{"trip":true}}],attrs:{"icon-only":"","icon":"MoreVerticalIcon","variant":"flat-primary"}})],1)])],2),_c('trips-setup',{attrs:{"modal-opened":_vm.tripsModalOpened},on:{"modalClosed":function($event){return _vm.closeModal('trips')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }