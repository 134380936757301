<template>
  <!--    Trip Form-->
  <validation-observer
      ref="tripForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updateTripMode?'Update':'Create'} Trip`"
        :show="tripModalOpened"
        :show-overlay="deletingTrip"
        size="md"
        @onClose="closeTripModal()"
    >
      <error-display :error="error"/>
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedTripId"
              :options="trips"
              label-name="title"
              value-name="id"
              placeholder="Trips"
              @input="populateTripsForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteTrip()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetTripForm()"
          />
        </div>
      </b-row>
      <lenon-input
          v-model="trip.title"
          name="title"
          placeholder="Enter Trip Title"
          rules="required"
          label="Title"
      />
      <lenon-select
          v-model="trip.driver_id"
          name="driver_id"
          placeholder="Select Driver"
          rules="required"
          label="Driver"
          :options="users"
          label-name="full_name"
          value-name="id"
      />
      <lenon-select
          v-model="trip.vehicle_id"
          name="vehicle_id"
          placeholder="Select Vehicle"
          rules="required"
          label="Vehicle"
          :options="vehicles"
          label-name="label"
          value-name="id"
      />
      <lenon-multi-select
          v-model="trip.conductors"
          name="conductors"
          placeholder="Select Conductors"
          label="Conductors"
          label-name="full_name"
          value-name="id"
          :options="users"
      />
      <div class="mb-1"></div>
      <lenon-multi-select
          v-model="trip.pickup_points"
          name="pickup_points"
          label="Pickup Points"
          placeholder="Select Pickup Points"
          rules="required"
          label-name="label"
          value-name="id"
          :options="pickupPoints"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeTripModal()"
          />
          <lenon-button
              :label="updateTripMode?'Update':'Create'"
              :disabled="invalid"
              :loading="tripLoading"
              loading-text="Loading..."
              @onClick="updateTripMode?updateTrip():createTrip()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_EXPENSE_M,
  DELETE_EXPENSE_M,
  UPDATE_EXPENSE_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'

export default {
  name: 'TripSetup',
  components: {
    LenonMultiSelect,
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      deletingTrip: false,
      tripModalOpened: false,
      updateTripMode: false,
      tripLoading: false,
      selectedTripId: null,
      trip: {
        id: null,
        title: null,
        vehicle_id: null,
        driver_id: null,
        conductors: [],
        pickup_points: [],
      },
    }
  },
  computed: {
    trips() {
      return this.$store.getters['trip/trips']
    },
    users() {
      return this.$store.getters['accountUsers/users']
    },
    vehicles() {
      return this.$store.getters['vehicle/vehicles']
    },
    pickupPoints() {
      return this.$store.getters['pickupPoint/pickupPoints']
    },
    selectedTrip() {
      return this
          .trips.find(g => +g.id === +this.selectedTripId)
    },
  },
  watch: {
    modalOpened(opened) {
      this.tripModalOpened = opened
    },
    selectedTripId(id) {
      if (!id) {
        this.resetTripForm()
      }
    },
    id(id) {
      if (id) {
        this.selectedTripId = id
      }
    },
  },
  methods: {
    resetTripForm() {
      this.updateTripMode = false
      this.trip = {
        id: null,
        title: null,
        vehicle_id: null,
        driver_id: null,
        conductors: [],
        pickup_points: [],
      }
      this.selectedTripId = null
      this.$refs.tripForm.reset()
    },
    populateTripsForm(ef) {
      if (this.selectedTrip) {
        this.updateTripMode = true
        this.trip = {
          ...this.selectedTrip,
          pickup_points: this.selectedTrip.pickup_points.map((id) => this.pickupPoints.find((p2) => +p2.id === +id)),
          conductors: this.selectedTrip.conductors.map((id) => this.users.find((p2) => +p2.id === +id)),
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.trip.__typename
      } else {
        this.resetTripForm()
      }
    },
    closeTripModal() {
      this.tripModalOpened = false
      this.$emit('modalClosed')
    },
    updateTrip() {
      this.error = {}
      if (!this.trip.id) {
        return
      }
      const trip = {
        ...this.trip,
        pickup_points: this
            .trip.pickup_points.map((p) => p.id),
        conductors: this.trip.conductors.map((p) => p.id)
      }
      this.tripLoading = true
      this.$http.put(`transportation/trips/${this.trip.id}`, trip)
          .then(res => {
            this.tripLoading = false
            this.showSuccess('Updated trip successfully')
            this.$store.commit('trip/updateTrip', res.data)
          })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to update trip')
            this.tripLoading = false
          })
    },
    createTrip() {
      this.error = {}
      this.tripLoading = true
      const trip = {
        ...this.trip,
        pickup_points: this
            .trip.pickup_points.map((p) => p.id),
        conductors: this.trip.conductors.map((p) => p.id)
      }
      this.$http.post('transportation/trips', trip)
          .then(res => {
            this.showSuccess('Created trip successfully')
            this.tripLoading = false
            this.$store.commit('trip/addTrip', res.data)
            this.resetTripForm()
          })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to create trip')
            this.tripLoading = false
          })
    },
    deleteTrip() {
      if (!this.selectedTripId) {
        this.showInfo('Please select a trip ')
        return
      }
      this.deletingTrip = true
      this.$http.delete(`transportation/trips/${this.selectedTripId}`)
          .then(() => {
            this.deletingTrip = false
            this.showSuccess('Deleted trip successfully')
            this.$store.commit('trip/removeTrip', this.selectedTripId)
            this.selectedTripId = null
            this.resetTripForm()
          })
          .catch(err => {
            logData(err)
            this.showError('Failed to delete trip')
            this.deletingTrip = false
          })
    },
  },
}
</script>
